export const resources = {
  "screenLogin.title": "ברוכים הבאים!",
  "screenLogin.description": "על מנת להיכנס יש להזין שם וסיסמא",
  "screenLogin.errorText": "למשתמש זה אין הרשאה. כדאי לבדוק את המייל והסיסמא",
  "screenLogin.chooseSegment.errorText": "יש לבחור ארגון על מנת להיכנס",
  "screenLogin.editPassword.errorText": "נא להזין מייל",

  "singelDatePicker.buttonToolTip.fromStartDate": "החל מתאריך",

  "screenHome.totalNumbersCubesRow.overview.title": "סקירה כללית",
  "screenHome.totalNumbersCubesRow.membersCount":
    "מספר חברי {{selectedSegmentName}} כעת",
  "screenHome.totalNumbersCubesRow.thisWeekNewMembersCount":
    "מספר חברי  {{selectedSegmentName}} שהצטרפו בשבוע האחרון ",
  "screenHome.totalNumbersCubesRow.totalMembersToDemandPayment":
    "חברים וחברות {{selectedSegmentName}} שעמדו ביעד לפחות פעם אחת בחודש {{date}}",
  "screenHome.totalNumbersCubesRow.totalMembersToDemandPaymentLeumit":
    "חברים וחברות {{selectedSegmentName}} שעמדו בקריטריונים לדרישת התשלום בחודש {{date}}",
  "screenHome.totalNumbersCubesRow.totalCoinsSum":
    "סך כל מטבעות חברי {{selectedSegmentName}} ",
  "screenHome.totalNumbersCubesRow.totalCoinsSum.todaysSum.tooltip":
    "מטבעות שנוספו אתמול",
  "screenHome.totalNumbersCubesRow.totalCoinsSum.todaysSum.value": "{{count}}+",
  "screenHome.totalNumbersCubesRow.avgImprovement.monthly":
    "ממוצע שיפור {{selectedSegmentName}} החודש",
  "screenHome.totalNumbersCubesRow.avgImprovement.weekly":
    "ממוצע שיפור {{selectedSegmentName}} השבוע",
  "screenHome.totalNumbersCubesRow.avgImprovement.display": "{{number}}%",
  "screenHome.totalNumbersCubesRow.avgImprovement.Minusdisplay":
    "{{number}}% -",
  "screenHome.topReferrals.tableTitle": "חבר מביא חבר",
  "screenHome.topReferrals.tableSubTitle": "מובילים בצירוף חברים ",
  "screenHome.topReferrals.addedNumber": "הוסיף/ה {{count}} חברים",
  "screenHome.topReferrals.addedNumber.singular": "הוסיף/ה חבר אחד",
  "screenHome.bonusesTotals.title": "התפלגות בונוסים",
  "screenHome.bonusesTotals.articles": "מאמרים",
  "screenHome.bonusesTotals.referral": "חבר מביא חבר",
  "screenHome.bonusesTotals.bmi": "BMI",
  "screenHome.bonusesTotals.articles.dataText": "{{value}} קוראים",
  "screenHome.bonusesTotals.referral.dataText": "{{value}} מצטרפים",
  "screenHome.bonusesTotals.bmi.dataText": "{{value}} הזינו",
  "screenHome.newUsersChart.title": "קצב הצטרפות חברים חדשים",
  "screenHome.newUsersChart.labelValueType": "מצטרפים חדשים",
  "screenHome.newUsersChart.dropDownOption.6Months": "בחצי שנה האחרונה",
  "screenHome.newUsersChart.dropDownOption.3Months": "ב-3 חודשים האחרונים",
  "screenHome.newUsersChart.dropDownOption.30Days": "ב-30 יום האחרונים",
  "screenHome.newUsersChart.dropDownOption.7Days": "בשבוע האחרון",
  "screenHome.newUsersChart.dropDownOption.90Days": "ב-90 יום האחרונים",
  "screenHome.openAppCountsChart.title": "התפלגות פתיחת האפליקציה",
  "screenHome.hideCoupon.title": "הסתרת הטבה",
  "screenHome.openAppCountsChart.labelValueType": 'סה"כ כניסות',
  "screenHome.openAppCountsChart.labelValueTypeDistinct": 'סה"כ משתמשים',
  "screenHome.activeUsers.title": "משתמשים פעילים",
  "screenHome.activeUsersChart.labelValueType": "משתמשים פעילים",
  "screenHome.changeSegment.all": "הכל",
  "screenHome.excel.appOpen.date": "תאריך",
  "screenHome.excel.appOpen.entries": "כמות כניסות",
  "screenHome.excel.appOpen.distinctCount": "כמות משתמשים",

  "screenPurchases.table.title": "רכישות",
  "screenPurchases.table.col.couponTitle": "שם השובר",
  "screenPurchases.table.col.businessName": "שם העסק",
  "screenPurchases.table.col.couponCount": 'סה"כ רכישות',
  "screenPurchases.table.col.segmentName": "שם הארגון",

  "topBar.logout.title": "התנתקות",
  "sideBar.menu.title": "תפריט",
  "sideBar.user.welcome": "היי {{userName}}",
  "sideBar.home.title": "בית",
  "sideBar.benefits.title": "פירוט הטבות",
  "sideBar.coupons.title": "הטבות",
  "sideBar.subSegmentUsers.title": "חלוקת משתמשים לארגונים",
  "sideBar.multiSegmentUsers.title": "חלוקת משתמשים",
  "sideBar.userConfirmaions.title": "הרשאות ואישורים",
  "sideBar.userBalance.title": `מעקב עו"ש`,
  "sideBar.support.editUserInfo": "עריכת פרטי משתמש",
  "sideBar.support.orderDetails": "פירוט הזמנה",
  "sideBar.support.sendNotificationToUser": "שליחת נוטיפיקציה",
  "sideBar.support.confirmHideCoupons": "אישור ביטול הטבות",
  "sideBar.appOpenByHours.title": "פתיחות לפי שעות",
  "sideBar.support.dailyStepsAndGoals": "צעדים ויעדים",
  "sideBar.traines.title": "משתתפים",
  "sideBar.staff.title": "אנשי צוות",
  "sideBar.appOpenByHours.hideCoupon": "הסתרת הטבה",
  "sideBar.healthAndFitnessData.title": "נתוני בריאות וכושר",

  "screenPurchaseUsersInfo.table.title": "פירוט רכישות",
  "screenPurchaseUsersInfo.table.col.couponTitle": "שם השובר",
  "screenPurchaseUsersInfo.table.col.couponCodeTitle": "קוד הטבה/מספר הזמנה",
  "screenPurchaseUsersInfo.table.col.identifier": "מספר תעודת זהות",
  "screenPurchaseUsersInfo.table.col.segmentId": "Segment",
  "screenPurchaseUsersInfo.table.col.phone": "טלפון",
  "screenPurchaseUsersInfo.table.col.userName": "שם המשתמש",
  "screenPurchaseUsersInfo.table.col.date": "תאריך",
  "screenPurchaseUsersInfo.table.col.segmentName": "שם הארגון",
  "screenPurchaseUsersInfo.table.col.pickUpPoint": `נק' איסוף`,

  "screenOrderDetails.errors.onlyNumbers": "מספר הזמנה יכול להכיל ספרות בלבד",
  "screenOrderDetails.errors.invalidInput": "מספר ההזמנה שהוקש לא תקין",
  "screenOrderDetails.errors.limitReached":
    "בוצעו מספר רב מדי של ניסיונות. יש לנסות שוב מאוחר יותר.",
  "screenOrderDetails.errors.orderNotExsist": "הזמנה זו אינה קיימת",
  "screenOrderDetails.orderId": "מספר הזמנה",
  "screenOrderDetails.title": "פירוט הזמנה",
  "screenOrderDetails.subtitle": `לקבלת פירוט יש להזין מספר הזמנה`,
  "screenOrderDetailsUser.table.col.date": "תאריך רכישה",
  "screenOrderDetails.table.col.couponTitle": "שם הטבה",
  "screenOrderDetails.table.col.business": "שם העסק",
  "screenOrderDetails.table.col.idNum": "ת.ז",
  "screenOrderDetails.table.col.address": "כתובת",
  "screenOrderDetails.table.col.phone": "טלפון",
  "screenOrderDetails.table.col.userName": "שם משתמש",
  "screenOrderDetails.table.col.email": "אמייל",

  "screenCouponsInInventory.table.title": "הטבות במלאי",
  "screenCouponsInInventory.table.col.couponTitle": "שם השובר",
  "screenCouponsInInventory.table.col.businessName": "שם העסק",
  "screenCouponsInInventory.table.col.couponCount": 'סה"כ במלאי',
  "screenCouponsInInventory.button.allInInventory": "כרגע במלאי",
  "screenCouponsInInventory.downloadExcel": "יצוא ל- Excel",
  "screenCouponsUser.title": "פירוט הטבות",
  "screenCouponsUser.searchButton": "חיפוש",
  "screenCouponsUser.table.title": `פירוט הטבות למשתמש  {{userName}}`,
  "screenCouponsUser.subtitle": "לקבלת פרטים יש להזין ת.ז",
  "screenCouponsUser.subtitle.idAndPhone": "לקבלת פרטים יש להזין ת.ז או טלפון",
  "screenCouponsUser.table.col.date": "תאריך ושעת רכישה",
  "screenCouponsUser.table.col.business": "שם העסק",
  "screenCouponsUser.table.col.supplier": "שם הספק",
  "screenCouponsUser.table.col.couponTitle": "תיאור הטבה",
  "screenCouponsUser.table.col.couponCode": "קוד הטבה",
  "screenCouponsUser.table.col.expireDate": "תוקף",
  "screenCouponsUser.table.chosen": "נבחרה",
  "screenCouponsUser.table.col.noMatch": "המשתמש טרם רכש הטבות",
  "screenCouponsUser.errors.onlyNumbers": `מספר תעודת זהות יכול להכיל ספרות בלבד`,
  "screenCouponsUser.errors.idNumberTooShort": `על מספר תעודת זהות להכיל לפחות 6 ספרות`,
  "screenCouponsUser.errors.userNotExsist":
    "לא מצאנו משתמש עם מספר תעודת זהות כזה",
  "screenCouponsUser.errors.userNotExsist.idOrPhone":
    "לא מצאנו משתמש עם ת.ז או טלפון כזה",
  "screenCouponsUser.errors.invalidInput": "המזהה שהוזן אינו תקין",
  "screenCouponsUser.errors.limitReached":
    "בוצעו מספר רב מדי של ניסיונות. יש לנסות שוב מאוחר יותר.",
  "screenCouponsUser.id": `ת.ז`,
  "screenCouponsUser.unHide": `חשיפת הטבה מחדש`,
  "screenCouponsUser.cancelCoupon": `ביטול הטבה`,
  "screenCouponsUser.approveCancelation": `כן`,
  "screenCouponsUser.rejectCancelation": `לא`,
  "screenCouponsUser.messegeBeforeCancelCoupon": `{{couponName}} - האם ברצונך לבטל את ההטבה?`,
  "screenCouponsUser.cancelHiddingCoupon": `חשיפת הטבה מחדש`,
  "screenCouponsUser.popUp.closeButton": `סגור`,
  "screenCouponsUser.popUp.cancelCouponSuccess": `הטבה בוטלה בהצלחה.`,
  "screenCouponsUser.popUp.cancelCouponSuccessMessege": `ההטבה חדלה מלהופיע למשתמש באפליקציה.
  המשתמש קיבל זיכוי במטבעות בריאות בשווי ההטבה.
  לתשומת ליבך - יש לציין כי יתכן שהמשתמש שמר תיעוד של קוד ההטבה
  (צילום מסך / כתב בצד) והוא עלול לממש אותה למרות הביטול.`,
  "screenCouponsUser.popUp.submissionCouponSuccess": `הבקשה לביטול ההטבה נשלחה בהצלחה.`,
  "screenCouponsUser.popUp.submissionCouponSuccessMessage": `לתשומת ליבך - ההטבה לא מבוטלת עד אישור המנהל.`,
  "screenCouponsUser.Modal.limitReached":
    "בוצעו מספר רב מדי של ניסיונות. יש לנסות שוב מאוחר יותר.",
  "screenCouponsUser.popUp.deliveryCouponCancelation":
    "לא ניתן לבטל הטבות שקשורות למערכת ההזמנות.",
  "screenCouponsUser.popUp.alreadyHidden": `הטבה זו כבר מוסתרת, המשתמש אינו יכול לראותה`,
  "screenCouponsUser.popUp.hiddenCouponSuccessMessege": `ההטבה חשופה למשתמש`,
  "screenCouponsUser.popUp.ecoCouponCancel": `לא ניתן לבטל הטבות אקולוגיות`,
  "screenCouponsUser.popUp.alreadySubmittedForHide": "ההטבה כבר נשלחה לאישור.",

  "screenConfirmHideCoupons.table.title": "הטבות לאישור ביטול",
  "screenConfirmHideCoupons.table.col.dateCreated": "תאריך ושעת רכישה",
  "screenConfirmHideCoupons.table.col.ownerId": "מזהה משתמש",
  "screenConfirmHideCoupons.table.col.submitterId": "אימייל המבקש",
  "screenConfirmHideCoupons.table.col.dateSubmitted": "תאריך הבקשה",

  "screenConfirmHideCouponsUser.button.confirmHiding": "אישור ביטול",
  "screenConfirmHideCouponsUser.button.cancelHiding": "לא מאושר לביטול",

  "support.sendNotificationScreen.subtitle": "הזן ת.ז ע״מ לקבל את משתמש היעד",
  "support.sendNotificationScreen.userNotExsist":
    "לא מצאנו משתמש עם מספר תעודת זהות כזה",
  "support.sendNotificationScreen.invalidData": "המזהה שהוזן אינו תקין",
  "support.sendNotificationScreen.idNumTooShort": `על מספר תעודת זהות להכיל לפחות 6 ספרות`,
  "support.sendNotificationScreen.onlyNumbers": `מספר תעודת זהות יכול להכיל ספרות בלבד`,
  "support.sendNotificationScreen.limitReached":
    "בוצעו מספר רב מדי של ניסיונות. יש לנסות שוב מאוחר יותר.",
  "support.sendNotificationScreen.userName": "שם משתמש: {{userName}}",
  "support.sendNotificationScreen.idNum": "ת״ז: {{idNum}}",
  "support.sendNotificationScreen.notificationNavigation":
    "מיקום הנחתת הנוטיפיקציה:",
  "support.sendNotificationScreen:chooseAllUsersButton": "בחירה בכל המשתמשים",
  "sendNotToUserSceern.navigationList.homeScreen": "מסך הבית",
  "sendNotToUserSceern.navigationList.articlesScreen": "מסך מאמרים",
  "sendNotToUserSceern.navigationList.externalLink": "לינק חיצוני",
  "sendNotToUserSceern.navigationList.activitiesScreen": "מסך עדכונים",
  "sendNotToUserSceern.navigationList.contentWorld": "עולם התוכן",
  "support.sendNotificationScreen.emptyText": "עליך להזין טקסט",
  "support.sendNotificationScreen.emptyLink": "עליך להזין לינק",
  "support.sendNotificationScreen.successSendNotificationTitlie":
    "שליחת נוטיפיקציה",
  "support.sendNotificationScreen.successSendNotificationContent":
    "נוטיפיקציה נשלחה בהצלחה!",
  "support.sendNotificationScreen.successSendNotificationButton": "אחלה",
  "screenSubSegmentUsers.table.title": "חלוקת משתמשים לארגונים",
  "screenSubSegmentUsers.table.col.subSegmentName": "שם הארגון",
  "screenSubSegmentUsers.table.col.count": "כמות משתמשים",
  "support.sendNotificationScreen.notificationType": "סוג נוטיפיקציה:",
  "support.sendNotificationScreen.emptyType": "עליך לבחור סוג נוטיפיקציה",
  "support.sendNotificationScreen.selfSendButton": "שלח אליי לבדיקה",
  "support.sendNotificationScreen.sendToUser": "שלח למשתמש",
  "support.sendNotificationScreen.toolTipContent":
    "יש לשלוח את הנוטיפיקציה לעצמך קודם ורק לאחר מכן למשתמש",
  "support.sendNotificationScreen.notificationType:updates": "פנימית",
  "support.sendNotificationScreen.notificationType:push": "חיצונית",

  "screenConfirmationUser.title": "הרשאות משתמש",
  "screenConfirmationUser.subtitle": "לקבלת פרטים יש להזין ת.ז",
  "screenConfirmationUser.id": `ת.ז`,
  "screenConfirmationUser.button": "שליחה",
  "screenConfirmationUser.errors.onlyNumbers": `מספר תעודת זהות יכול להכיל ספרות בלבד`,
  "screenConfirmationUser.errors.idNumberTooShort": `על מספר תעודת זהות להכיל לפחות 6 ספרות`,
  "screenConfirmationUser.errors.userNotExsist":
    "לא מצאנו משתמש עם מספר תעודת זהות כזה",
  "screenConfirmationUser.errors.invalidInput": "המזהה שהוזן אינו תקין",
  "screenConfirmationUser.MaillingConfirmations.startDate": "החל מ",

  "verticalTableConfirmation.table.title": "אישורים",
  "verticalTableConfirmation.table.col.userName": "שם משתמש",
  "verticalTableConfirmation.table.col.approvalPolicy": "אישור תקנון",
  "verticalTableConfirmation.table.col.approvalMailing": "אישור דיוור",
  "verticalTableConfirmation.table.col.approvalMailingDate":
    "שינוי אישור דיוור",

  "screenUserBalance.title": `מעקב עו"ש`,
  "screenUserBalance.subtitle": "לקבלת פרטים יש להזין ת.ז",
  "screenUserBalance.table.title": `מעקב עו"ש של המשתמש  {{userName}}`,
  "screenBalanceUser.button": "חיפוש",
  "screenUserBalance.table.col.date": "תאריך ושעת פעולה",
  "screenUserBalance.table.col.business": "הפעולה",
  "screenUserBalance.table.col.cost": "חובה/זכות",
  "screenUserBalance.table.col.total": `יתרה במטבעות בריאות`,
  "screenUserBalance.table.col.noMatch": "המשתמש טרם ביצע פעולות",
  "screenUserBalance.errors.onlyNumbers": `מספר תעודת זהות יכול להכיל ספרות בלבד`,
  "screenUserBalance.errors.idNumberTooShort": `על מספר תעודת זהות להכיל לפחות 6 ספרות`,
  "screenUserBalance.errors.userNotExsist":
    "לא מצאנו משתמש עם מספר תעודת זהות כזה",
  "screenUserBalance.errors.userNotExsist.idOrPhone":
    "לא מצאנו משתמש עם ת.ז או טלפון כזה",
  "screenUserBalance.errors.invalidInput": "המזהה שהוזן אינו תקין",
  "screenUserBalance.errors.limitReached":
    "בוצעו מספר רב מדי של ניסיונות. יש לנסות שוב מאוחר יותר.",
  "screenUserBalance.id": `ת.ז`,

  "screenCouponsUser.errors.notHidden": "{{couponName}} - הטבה זו אינה מוסתרת",
  "screenCouponsUser.successCouponHidden":
    "{{couponName}} - ההטבה נחשפה בהצלחה, כעת המשתמש יכול לראותה.",
  "screenCouponsUser.successCouponRestored":
    "{{couponName}} — ההטבה לא אושרה לביטול",
  "screenCouponUser.button.recover.1":
    "קיימת אפשרות בה המשתמש ״מוחק״ את ההטבה מהאפליקציה ע״י לחיצה על אייקון ״פח״.",
  "screenCouponUser.button.recover.2":
    "במידה והמשתמש בחר לעשות כן והתחרט, ישנה אפשרות לחשוף מחדש את ההטבה למשתמש.",
  "screenCouponUser.button.recover.3":
    "לחיצה על כפתור זה תחשוף מחדש את ההטבה המסומנת למשתמש.",
  "screenCouponUser.button.delete.1": "ההטבה תחדול מלהופיע למשתמש באפליקציה.",
  "screenCouponUser.button.delete.2":
    "המשתמש יקבל זיכוי במטבעות בריאות בשווי ההטבה.",
  "screenCouponUser.button.delete.3":
    "לתשומת ליבך - יש לציין כי יתכן שהמשתמש שמר תיעוד של קוד ההטבה (צילום מסך / כתב בצד) והוא עלול לממש אותה למרות הביטול.",

  "screenEditUserInfo.id": `ת.ז`,
  "screenEditUserInfo.username": "שם משתמש",
  "screenEditUserInfo.emailAdress": "כתובת מייל",
  "screenEditUserInfo.userPhone": "מספר טלפון",
  "screenEditUserInfo.button.save": "שמירת נתונים",
  "screenEditUserInfo.editInfo.sucessText": "פרטים עודכנו בהצלחה",
  "screenEditUserInfo.button.resetPassword": "שליחת מייל לאיפוס סיסמא",
  "screenEditUserInfo.resetPassword.success":
    "מייל איפוס סיסמא נשלח לכתובת - {{email}}",
  "screenEditUserInfo.button.resetPassword.error": "קרתה תקלה בשליחת המייל",
  "screenEditUserInfo.button.unblockUniqueDeviceId":
    "פתיחת חסימת מעבר בין מכשירים",
  "screenEditUserInfo.button.unblockUniqueDeviceId.success":
    "חסימה שוחררה בהצלחה",
  "screenEditUserInfo.button.send": "חיפוש",
  "screenEditUserInfo.title": "עריכת פרטי משתמש",
  "screenEditUserInfo.subtitle": "לקבלת פרטים יש להזין ת.ז",
  "screenEditUserInfo.entitlementStatus": "מצב זכאות:",
  "screenEditUserInfo.entitled": "זכאי",
  "screenEditUserInfo.notEntitled": "לא זכאי",
  "screenEditUserInfo.errors.onlyNumbers": `מספר תעודת זהות יכול להכיל ספרות בלבד`,
  "screenEditUserInfo.errors.idNumberTooShort": `על מספר תעודת זהות להכיל לפחות 6 ספרות`,
  "screenEditUserInfo.errors.userNotExsist":
    "לא מצאנו משתמש עם מספר תעודת זהות כזה",
  "screenEditUserInfo.errors.userNotExsist.idOrPhone":
    "לא מצאנו משתמש עם ת.ז או טלפון כזה",
  "screenEditUserInfo.errors.emptyUserName": "שם משתמש אינו יכול להיות ריק",
  "screenEditUserInfo.errors.invalidInput": "המזהה שהוזן אינו תקין",
  "screenEditUserInfo.errors.emptyEmail": "כתובת המייל אינה יכולה להיות ריקה",
  "screenEditUserInfo.errors.invalidEmail": "כתובת המייל אינה תקינה",
  "screenEditUserInfo.errors.usedEmail":
    "לא ניתן לשנות את המייל לכתובת זו היות והיא כבר נמצאת בשימוש במערכת",
  "screenEditUserInfo.errors.general": "קרתה תקלה ולכן הפעולה לא בוצעה",
  "screenEditUserInfo.errors.limitReached":
    "בוצעו מספר רב מדי של ניסיונות. יש לנסות שוב מאוחר יותר.",
  "screenEditUserInfo.errors.invalidPhone": "מספר הטלפון אינו תקין",
  "screenEditUserInfo.removedSpecialCharacters":
    "שימו לב - הוסרו תווים לא חוקיים מכתובת המייל החדשה. הכתובת במערכת היא: {{email}}",
  "screenEditUserInfo.otpCode.label": "קוד OTP",
  "screenEditUserInfo.otpCode.sendButton": "שליחת קוד OTP",

  "OtpCode:errorText.general": "אופס משהו השתבש",
  "OtpCode:errorText.invalidCode": "קוד OTP שגוי",
  "OtpCode:errorText.phoneHasBeenUsed": "מספר הטלפון הזה אומת עבור משתמש אחר",
  "OtpCode:errorText.rateLimiterExceeded":
    "עברת את מספר הנסיונות המותר, יש לנסות שוב יותר מאוחר",
  "OtpCode:errorText.invalidPhoneNumber": " מספר הטלפון לא תקין",

  "screenEntitlementData.entitlementSideBarTitle": "בדיקת זכאות",
  "screenEntitlementData.entitlement": "זכאות",
  "screenEntitlementData.notEntitled": "לא זכאי",
  "screenEntitlementData.entitlementStatus": "סטטוס תביעה:",
  "screenEntitlementData.entitled": "זכאי",
  "screenEntitlementData.phone": "טלפון מאומת: ",
  "screenEntitlementData.underage": "קטין: ",
  "screenEntitlementData.underage.yes": "כן",
  "screenEntitlementData.underage.no": "לא",
  "screenEntitlementData.otpTitle": "קוד הזדהות",
  "screenEntitlementData.otpSent": "מועד שליחת הודעת אימות למשתמש: ",
  "screenEntitlementData.otpCode": "קוד אימות: ",
  "screenEntitlementData.otpStatus": "סטטוס קוד: ",
  "screenEntitlementData.otpStatus.active": "פעיל",
  "screenEntitlementData.otpStatus.expired": "פג תוקף",
  "screenEntitlementData.entitlementHistoryTable.date": "תאריך",
  "screenEntitlementData.entitlementHistoryTable.status": "סטטוס חדש",
  "screenEntitlementData.underageStatus": "אישור קטינים",
  "screenEntitlementData.underageRequestStatus": "סטטוס אישור הורים: ",
  "screenEntitlementData.underageRequestApprovalDate": "מועד האישור: ",
  "screenEntitlementData.underageRequestStatusDate":
    "מועד שליחת SMS להורה עם לינק לאישור: ",
  "screenEntitlementData.underageRequestStatus.approved": "אושר",
  "screenEntitlementData.underageRequestStatus.denied": "לא אושר",

  "screenMultiSegmentUsers.table.title": "חלוקת משתמשים לארגונים",
  "screenMultiSegmentUsers.table.col.multiSegmentName": "שם הארגון",
  "screenMultiSegmentUsers.table.col.count": "כמות משתמשים",

  "screenAppOpenByHours.dropDownOption.lastDay": "יום אחד",
  "screenAppOpenByHours.dropDownOption.lastThreeDays": "שלושה ימים",
  "screenAppOpenByHours.dropDownOption.lastWeek": "שבוע",
  "screenAppOpenByHours.openAppCountsChart.labelValueType": `סה"כ כניסות`,
  "screenAppOpenByHours.excelTitle": "פתיחת האפליקציה לפי שעות",
  "screenAppOpenByHours.downloadExcelButton.title": "יצוא ל-EXCEL",
  "screenAppOpenByHours.excelCol.Date": "תאריך ושעה",
  "screenAppOpenByHours.excelCol.count": "כמות המשתמשים שפתחו את האפליקציה",

  "screenHealthAndFitnessData.data.export.allCops": `ייצוא דו"ח כל השוטרים`,
  "screenHealthAndFitnessData.data.export.oneCop": `ייצוא דו"ח שוטר/ת`,
  "screenHealthAndFitnessData.data.start": "נתוני התחלה",
  "screenHealthAndFitnessData.data.title": "נתוני בריאות",
  "screenHealthAndFitnessData.data.middle": "נתוני אמצע",
  "screenHealthAndFitnessData.data.finish": "נתוני סיום",
  "screenHealthAndFitnessData.data.systemFeed": "הזנות למערכת",
  "screenHealthAndFitnessData.search.label": "חיפוש תעודת זהות שוטר/ת:",
  "screenHealthAndFitnessData.search.placeholder": "חיפוש תעודת זהות שוטר/ת",
  "screenHealthAndFitnessData.search.button": "חיפוש",
  "screenHealthAndFitnessData.officerData.header": "נתוני השוטר/ת:",
  "screenHealthAndFitnessData.bloodPressure.label": "בדיקת לחץ דם",
  "screenHealthAndFitnessData.bloodPressure.systolic.subtext": `*סיסטולי`,
  "screenHealthAndFitnessData.bloodPressure.diastolic.subtext": `*דיאסטולי`,
  "screenHealthAndFitnessData.weight.label": "בדיקת משקל",
  "screenHealthAndFitnessData.weight.subText": `*יש לכתוב משקל בק״ג`,
  "screenHealthAndFitnessData.pushups.label": "מבדק שכיבות שמיכה",
  "screenHealthAndFitnessData.star.label": "מבדק כוכב",
  "screenHealthAndFitnessData.waistCircumference.label": "בדיקת היקף מותניים",
  "screenHealthAndFitnessData.waistCircumference.subText": `*יש לכתוב היקף בס״מ`,
  "screenHealthAndFitnessData.squat.label": "מבדק סקווט",
  "screenHealthAndFitnessData.fatPercentage.label": "בדיקת אחוזי שומן",
  "screenHealthAndFitnessData.rowing.label": "מבדק חתירה בישיבה",
  "screenHealthAndFitnessData.selectType.label": "בחירת סוג הנתונים לעריכה:",
  "screenHealthAndFitnessData.submit": "שמור",
  "screenHealthAndFitnessData.personalDetails.id": "מספר תעודת זהות",
  "screenHealthAndFitnessData.personalDetails.name": "שם",
  "screenHealthAndFitnessData.excel.counter": `מס״ד`,
  "screenHealthAndFitnessData.excel.bloodPressure": `בדיקת לחץ דם`,
  "screenHealthAndFitnessData.excel.weight": `(בק״ג) בדיקת משקל`,
  "screenHealthAndFitnessData.excel.waist": `(בס״מ) בדיקת היקף מותניים`,
  "screenHealthAndFitnessData.excel.fatPercentage": `בדיקת אחוזי שומן`,
  "screenHealthAndFitnessData.excel.pushups": `מבדק שכיבות שמיכה`,
  "screenHealthAndFitnessData.excel.star": `מבדק כוכב`,
  "screenHealthAndFitnessData.excel.squat": `מבדק סקווט`,
  "screenHealthAndFitnessData.excel.rowing": "מבדק חתירה בישיבה",
  "screenHealthAndFitnessData.excel.filename.allCops": `דו"ח נתוני בריאות שוטרים`,
  "screenHealthAndFitnessData.excel.filename.oneCop.startText": `דו"ח נתוני בריאות`,
  "screenHealthAndFitnessData.excel.filename.oneCop.sheetName": `נתוני בריאות שוטר`,
  "screenHealthAndFitnessData.popup.title": "שמירת נתונים",
  "screenHealthAndFitnessData.popup.content": "הזנתך נקלטה בהצלחה",
  "screenHealthAndFitnessData.popup.button": "אישור",
  "screenHealthAndFitnessData.error.noData": "אין נתונים",
  "screenHealthAndFitnessData.error.dataNotSaved": "הנתונים לא נשמרו",
  "screenHealthAndFitnessData.error.onlyNumbers": "זה יכול להכיל ספרות בלבד",
  "screenHealthAndFitnessData.error.noSegment": " נא לבחור פרוייקט",

  "organizationDashboard.confirmations.tooltip1":
    "במסך זה ניתן לחפש כל חבר/ה בפרויקט על ידי שימוש בת.ז. ",
  "organizationDashboard.confirmations.tooltip2":
    "המסך מציג עבור כל חבר/ה תאריך אישור תקנון ורשימת שינויי אישור דיוור.",
  "organizationDashboard.subSegmentUsers.tooltip":
    "במסך זה מופיעה רשימת שמות הארגונים כפי שמופיעים במערכת ומספר העובדים בהם הרשומים לתכנית.",
  "organizationDashboard.appOpenByHours.tooltip":
    "מסך זה מציג את נתוני פתיחת האפליקציה בחלוקה לשעות ולתאריך.",
  "organizationDashboard.hideCoupon.tooltip":
    "במסך זה ניתן להסתיר הטבה מרשימת ההטבות המוצגות באפליקציה.",
  "organizationDashboard.purchases.tooltip":
    "במסך זה מופיעים כל ההטבות שנרכשו על ידיי חברי הפרויקט בחלוקה לפי שם או סוג בית עסק או ההטבה.",
  "organizationDashboard.couponsInInventory.tooltip1":
    "במסך זה מופיעות רשימת ההטבות והמלאי הקיים בכל אחת מהן.",
  "organizationDashboard.couponsInInventory.tooltip2":
    "ניתן לבחור כל תקופה בין שני תאריכים על מנת לראות אילו הטבות שנמצאות כרגע במלאי, נוספו בתקופה הנבחרת.",
  "organizationDashboard.purchasesUsersInfo.tooltip1":
    'במסך זה מופיעה רשימת ההטבות הייעודיות בלבד שנרכשו ע"י חברי הארגון, בחלוקה לפי משתמשים.',
  "organizationDashboard.purchasesUsersInfo.tooltip2":
    "כלומר, במסך זה לא יופיעו הטבות שנרכשו מה eco system של Rumble.",
  "organizationDashboard.hideCouponType.selectCouponType": "בחר הטבה",
  "organizationDashboard.hideCouponType.hideCouponTypeButton": "הסתר הטבה",
  "organizationDashboard.hideCouponType.AreYouSurePopUpTitle": "הסתרת הטבה",
  "organizationDashboard.hideCouponType.AreYouSurePopUpContent": `האם אתה בטוח שברצונך להסתיר את הטבת {{couponTitle}}?`,
  "organizationDashboard.hideCouponType.AreYouSurePopUpContent2": `לידיעתך, לא תוכל לחשוף את ההטבה לאחר ההסתרה.`,
  "organizationDashboard.hideCouponType.successHideCoupn": `ההטבה {{couponTypeTitle}} הוסתרה בהצלחה.`,
  "organizationDashboard.hideCouponType.successHideCouponNote": `יש להמתין עד דקה על מנת לראות את השינוי באפליקציה עצמה.`,
  "screenTrainesInfo.table.title": "מידע על משתתפים",
  "screenTrainesInfo.tooltip.text1":
    "תצוגת מידע על משתתפי פרויקט עתיד בריא, בחלוקה למחזורים. התצוגה משקפת מידע נכון לאתמול. במידה ורוצים מידע נכון לעכשיו, ניתן לייצא EXCEL שיישלח במייל, למייל איתו נרשמת לאפליקציה.",
  "screenTrainesInfo.tooltip.police":
    "תצוגת מידע על משתתפי המשטרה. התצוגה משקפת מידע נכון לאתמול. במידה ורוצים מידע נכון לעכשיו, ניתן לייצא EXCEL שישלח במייל, למייל איתו נרשמת לאפליקציה.",

  "screenTrainesInfo.table.col.userName": "שם באפליקציה",
  "screenTrainesInfo.table.col.identifier": "מזהה",
  "screenTrainesInfo.table.col.phone": "טלפון",
  "screenTrainesInfo.table.col.email": "אימייל",
  "screenTrainesInfo.table.col.registrationDate": "תאריך הרשמה",
  "screenTrainesInfo.table.col.lastOpenDate": "תאריך התחברות אחרון",
  "screenTrainesInfo.table.col.stepsAvg": "ממוצע צעדים יומי בחודש האחרון",
  "screenTrainesInfo.table.col.appOpens": "כניסות לאפליקציה בחודש האחרון",
  "screenTrainesInfo.table.col.articleReading": "קריאות מאמרים",
  "screenTrainesInfo.table.col.videosConsumption": "צפיות בסרטונים",
  "screenTrainesInfo.table.col.lastContentConsumption":
    "תאריך צריכת תוכן אחרונה",
  "screenTrainesIfo.table.col.policeName": "שם שוטר",
  "screenTrainesInfo.table.col.policeId": "ת.ז שוטר",
  "screenTrainesIfo.table.col.policePhone": "טלפון שוטר",
  "screenTrainesInfo.table.col.conect": "סטטוס",
  "screenTrainesInfo.table.col.registrationDate": "תאריך הרשמה",
  "screenTrainesInfo.table.col.lastLoginDate": "תאריך התחברות אחרון",
  "screenTrainesInfo.table.col.email": "אימייל",
  "screenTrainesInfo.table.col.rumbleNickName": "כינוי ב-RUMBLE",
  "screenTrainesInfo.table.col.lastDepositDate": "תאריך המרת צעדים אחרון",
  "screenTrainesInfo.table.col.avgStepsLast30Days":
    "כמות צעדים ממוצעת יומית בחודש האחרון",
  "screenTrainesInfo.table.col.countAppOpenLast30Days":
    "כמות כניסות לאפליקציה בחודש האחרון",
  "screenTrainesInfo.table.col.countPurchaseRumbleAcoSystemCoupons":
    "כמות רכישת הטבות כלליות מהאפליקציה",
  "screenTrainesInfo.table.col.lastPurchaseAtidCouponDate":
    "תאריך רכישה אחרון של הטבת עתיד בריא",
  "screenTrainesInfo.table.col.lastPurchaseAtidCouponName":
    "מה רכש מהטבת עתיד בריא",
  "screenTrainesInfo.table.col.lastArticleReadDate":
    "תאריך צריכת תוכן ומענה על שאלות אחרון",
  "screenTrainesInfo.table.col.lastArticleReadName": "שם התוכן",
  "screenTrainesInfo.table.col.countReadAtricleInLast6Month":
    "כמה תכנים צרך וענה בחצי שנה האחרונה",
  "screenTrainesInfo.sendExcelPopup.title": 'שליחת דו"ח משתתפים במייל',
  "screenTrainesInfo.sendExcelPopup.description":
    'בדקות הקרובות יישלח הדו"ח אל כתובת המייל: {{email}}',

  "screenStaffInfo.table.title": "מידע על אנשי צוות",
  "screenStaffInfo.tooltip.text1":
    "תצוגת מידע על אנשי צוות בפרויקט עתיד בריא. התצוגה משקפת מידע נכון לאתמול. במידה ורוצים מידע נכון לעכשיו, ניתן לייצא EXCEL שיישלח במייל, למייל איתו נרשמת לאפליקציה.",
  "screenStaffInfo.sendExcelPopup.title": 'שליחת דו"ח אנשי צוות במייל',
  "screenStaffInfo.sendExcelPopup.description":
    'בדקות הקרובות יישלח הדו"ח אל כתובת המייל: {{email}}',
  "screenStaffInfo.table.col.name": "שם",
  "screenStaffInfo.table.col.phone": "מספר טלפון",
  "screenStaffInfo.table.col.groupName": "קבוצה {{groupNumber}}",

  "screenDailyStepsAndGoals.title": "מעקב אחר צעדים ויעדים",
  "screenDailyStepsAndGoals.subtitle": "לקבלת פרטים יש להזין ת.ז",
  "screenDailyStepsAndGoals.id": `ת.ז`,
  "screenDailyStepsAndGoals.errors.userNotExsist.idOrPhone":
    "לא מצאנו משתמש עם ת.ז או טלפון כזה",
  "screenDailyStepsAndGoals.table.title": `פירוט צעדים יומיים ויעדים של המשתמש  {{userName}}`,
  "screenDailyStepsAndGoals.table.col.day": "תאריך",
  "screenDailyStepsAndGoals.table.col.dailySteps": "סכום צעדים שנצעדו היום",
  "screenDailyStepsAndGoals.table.col.weeklySteps": "סכום צעדים שנצעדו השבוע",
  "screenDailyStepsAndGoals.table.col.goal": "יעד יומי",
  "screenDailyStepsAndGoals.table.col.weeklyGoal": "יעד שבועי",
  "screenDailyStepsAndGoals.table.col.goalMet": "עמד/לא עמד",
  "screenDailyStepsAndGoals.table.col.noMatch": "למשתמש אין עדיין מידע",
  "screenDailyStepsAndGoals.table.userDidMetGoal": "עמד",
  "screenDailyStepsAndGoals.table.userDidNotMetGoal": "לא עמד",
  "screenDailyStepsAndGoals.informationButton.recover":
    "על מנת לצפות בפירוט הצעדים המעודכן תחת ״סכום צעדים שנצעדו״ על המשתמש להמיר צעדים.  במידה ומשתמש טוען שהלך יותר צעדים מהרשום - יש לבדוק מול איזו אפליקציה ביצע השוואה ולצרף צילומי מסך לפניה.",
  "screenDailyStepsAndGoals.errors.onlyNumbers": `מספר תעודת זהות יכול להכיל ספרות בלבד`,
  "screenDailyStepsAndGoals.errors.idNumberTooShort": `על מספר תעודת זהות להכיל לפחות 6 ספרות`,
  "screenDailyStepsAndGoals.errors.userNotExsist":
    "לא מצאנו משתמש עם מספר תעודת זהות כזה",
  "screenDailyStepsAndGoals.errors.invalidInput": "המזהה שהוזן אינו תקין",
  "screenDailyStepsAndGoals.errors.limitReached":
    "בוצעו מספר רב מדי של ניסיונות. יש לנסות שוב מאוחר יותר.",
  "screenDailyStepsAndGoals.subTitle.note": `יש לשים לב: במידה ורשום שהמשתמש עמד ביעד - לא בהכרח הוא היה זכאי לבונוס. יש לבדוק מולו מתי ביצע את המרת הצעדים.
במידה וביצע זאת אחרי יום שבת של אותו שבוע - הוא אינו זכאי לבונוס בגין הימים שלא המיר בהם.`,

  "sideBar.businessDashboardUser.title": `דשבורד עסקים`,
  "screenBusinessDashboardUser.title": "הוספת משתמש לדשבורד עסקים",
  "screenBusinessDashboardUser.dropDownTitle": "בחר עסק",
  "screenBusinessDashboardUser.inputEmail": "כתובת מייל",
  "screenBusinessDashboardUser.inputContactName": "שם בעל העסק",
  "screenBusinessDashboardUser.businessPhone": "טלפון",
  "screenBusinessDashboardUser.createUserButton": "צור משתמש",
  "screenBusinessDashboardUser.createUser.success": "הפעולה בוצעה בהצלחה",
  "screenBusinessDashboardUser.createUser.progress": "אנא המתן לסיום הפעולה",
  "screenBusinessDashboardUser.errors.invalidEmail": "כתובת המייל אינה תקינה",
  "screenBusinessDashboardUser.errors.emptyContactName":
    "שם בעל העסק לא יכול להיות ריק",
  "screenBusinessDashboardUser.errors.emptyBusinessId": "יש לבחור עסק",
  "screenBusinessDashboardUser.errors.userAlreadyWithPermission":
    "לעסק זה יש כבר הרשאות לדשברוד עסק תחת כתובת המייל הזו",
  "screenBusinessDashboardUser.errors.general":
    "קרתה תקלה ולכן הפעולה לא בוצעה",

  "screenAddNewBusiness.AddBusinessFormTitle": "העלאת עסק לאפליקציה",
  "screenAddNewBusiness.GeneralInfo": "מידע כללי",
  "screenAddNewBusiness.BusinessName": "שם בית העסק",
  "screenAddNewBusiness.StartWorkingWithBusiness": "תאריך מכירה",
  "screenAddNewBusiness.Subtitle": "כותרת משנית",
  "screenAddNewBusiness.BusinessHours": "שעות פעילות",
  "screenAddNewBusiness.BusinessDescription": "פירוט קצר על בית העסק",
  "screenAddNewBusiness.PrimaryCategory": "קטגוריה ראשית",
  "screenAddNewBusiness.BusinessLocation": "מיקום",
  "screenAddNewBusiness.isKosher": " האם כשר?",
  "screenAddNewBusiness.isActive": "האם העסק פעיל?",
  "screenAddNewBusiness.isUnhealthy": `האם נחשב כעסק לא בריא? (סימון עסק כלא בריא לא יופיע לסגמנטים המוגדרים בריאים)`,
  "screenAddNewBusiness.isLocal": "האם זה עסק מקומי?",
  "screenAddNewBusiness.isOnline": "האם אונליין?",
  "screenAddNewBusiness.isVeg": " האם צמחוני?",
  "screenAddNewBusiness.Media": "קישורים חיצוניים",
  "screenAddNewBusiness.SiteUrl": "כתובת אתר",
  "screenAddNewBusiness.FacebookUrl": "קישור לפייסבוק",
  "screenAddNewBusiness.InstagramUrl": "קישור לאינסטגרם",
  "screenAddNewBusiness.ContactInformation": "פרטי התקשרות",
  "screenAddNewBusiness.ContactName": "שם איש קשר עסקי",
  "screenAddNewBusiness.ContactEmail": `דוא"ל ליצירת קשר עסקי`,
  "screenAddNewBusiness.ContactPhone": "טלפון ליצירת קשר עסקי",
  "screenAddNewBusiness.CustomerServiceEmail": `דוא"ל שירות לקוחות`,
  "screenAddNewBusiness.CustomerServicePhone": `טלפון שירות לקוחות`,
  "screenAddNewBusiness.InternalContactInfo": `מידע אודות העסק לשימוש פנימי`,
  "screenAddNewBusiness.ExternalContactInfo": `מידע אודות העסק שמשתקף באפליקציה`,
  "screenAddNewBusiness.AccountInfo": "פרטי התחשבנות",
  "screenAddNewBusiness.CompanyInfo": "מידע על החברה",
  "screenAddNewBusiness.Retainer": "תשלום חודשי",
  "screenAddNewBusiness.RetainerInstructions": "אם אין עמלה, יש להכניס 0.",
  "screenAddNewBusiness.Commission": "עמלה",
  "screenAddNewBusiness.CommissionPercent": "אחוז עמלה",
  "screenAddNewBusiness.Score": "ציון",
  "screenAddNewBusiness.HealthScore": "ציון בריאות",
  "screenAddNewBusiness.ChooseLimitationType": "בחירת הגבלות רכישה בעסק",
  "screenAddNewBusiness.NumberOfCouponsLimitation": "כמות הטבות",
  "screenAddNewBusiness.NumberOfDays": "כמות ימים",
  "screenAddNewBusiness.NumberOfMonths": "כמות חודשים",
  "screenAddNewBusiness.SegmentChoice": "בחירת סגמנט",
  "screenAddNewBusiness.isEmailAtLike":
    "שליחת מייל בעת סימון ״אהבתי״ על הטבות של העסק באפליקציה",
  "screenAddNewBusiness.isEmailAtNotification":
    "שליחת מייל בעת הוצאת נוטיפיקציה",
  "screenAddNewBusiness.isEmailAtPurchase":
    "שליחת מייל בעת רכישת הטבה של העסק באפליקציה",
  "screenAddNewBusiness.ChooseDescriptiveTagCategory": "קטגוריית טאגים כללית",
  "screenAddNewBusiness.DescriptiveTagSelect": "בחירת טאגים",
  "screenAddNewBusiness.DescriptiveTagsTitle": "אפיון העסק",
  "screenAddNewBusiness.TagsLabel": "טאגים (מילות חיפוש להטבה באפליקציה)",
  "screenAddNewBusiness.BusinessLogo": "לוגו בית העסק",
  "screenAddNewBusiness.ImageTooLargeMessage":
    "תמונה גדולה מדי, אנא להעלות תמונה קטנה יותר",
  "screenAddNewBusiness.ErrorPopUpTitle": "שגיאה",
  "screenAddNewBusiness.CreateBusinessButton": "צור עסק",
  "screenAddNewBusiness.SuccessPopUpTitle": "הצלחה",
  "screenAddNewBusiness.NegativeNumberError": "ערך לא תקין.",
  "screenAddNewBusiness.FailedLocationError":
    "העסק לא נוצר מכיוון שהמיקום לא תקין:",
  "screenAddNewBusiness.FailedBusinessError": "נסיון ליצירת עסק נכשל",
  "screenAddNewBusiness.BusinessCreatedSuccess": "העסק נוצר בהצלחה",
  "screenAddNewBusiness.EmptyFieldError": "אחד או יותר מהשדות הנדרשים אינו מלא",
  "screenAddNewBusiness.purchaseCount": "הגבלה לפי מספר ימים וכמות רכישות",
  "screenAddNewBusiness.purchaseCountInCalendarMonth":
    "הגבלת כמות רכישות בחודש קלנדרי",
  "screenAddNewBusiness.oneCouponPerBusiness":
    "הגבלה לרכישת הטבה אחת בלבד מבית העסק",
  "screenAddNewBusiness.noLimitation": "בלי הגבלה",
  "screenAddNewBusiness.ChooseSubcategoriesLabel": "בחר קטגוריות משניות",
  "screenAddNewBusiness.SegmentTooltip": `על מנת להעלות את העסק לכל הסגמנטים באפליקציה (חוץ מאלו שיש להם החרגה אוטומטית) בחר בסמגנט כללי בלבד.
  אם ברצונך להעלות את האפליקציה לסגמנט ספציפי בלבד אנא ציין את שם הסגמנט.`,
  "screenAddNewBusiness.BusinessLocationPlaceholder": `במידה ויש יותר ממיקום אחד, 
  הכנס כל מיקום בשורה חדשה 
  דוגמא: 
  אבן גבירול 15, תל אביב
  המסגר 33, חיפה`,
  "screenAddNewBusiness.BusinessHoursPlaceHolder": `דוגמא:
  א': 19:00 - 10:00 
  ב'-ה': 19:30- 09:30
  שישי: 14:00 - 08:00      
  שבת: סגור`,
  "screenAddNewBusiness.TagsPlaceholder": `אנא הכנס מילות חיפוש רלוונטיים
  דוגמא:
  Fitness
  Sport
  Fun`,
  "screenAddNewBusiness.SizesPlaceholder": `דוגמא:
  34
  45
  46-47`,
  "screenAddNewBusiness.ColorsPlaceholder": `דוגמא:
  ירוק
  אדום
  כחול`,
  "screenAddNewBusiness.colors": "צבעים",
  "screenAddNewBusiness.sizes": "מידות",
  "screenAddNewBusiness.MissingSupplierId": "חסר ספק",

  "addNewCouponType.AddCouponTypeFormTitle": "העלאת הטבה",
  "addNewCouponType.CouponTypeCreatedSuccess": "ההטבה נוצרה בהצלחה",
  "addNewCouponType.CouponTypeCreatedFailure": "נסיון ליצירת הטבה נכשל",
  "addNewCouponType.CreateCouponTypeButton": "צור הטבה",
  "addNewCouponType.AddCouponTypeExternalSubtitle": "מידע כללי על ההטבה",
  "addNewCouponType.AddCouponTypeInternalSubitle": "מידע כללי לשימוש פנימי",
  "addNewCouponType.generateRandomCodes": "צור לי שוברים",
  "addNewCouponType.ExpiresAtLabel": "בחר תאריך תפוגה",
  "addNewCouponType.ActiveDurationTime": "זמן הצגת קוד רכישת ההטבה באפליקציה",
  "addNewCouponType.CodeInput": "תן לי להכניס שוברים בעצמי",
  "addNewCouponType.NoExternalCodeInput": "אינני מעוניין להכניס קודים כעת",
  "addNewCouponType.SelectExternalType": "בחר אופציה",
  "addNewCouponType.Price": "מחיר",
  "addNewCouponType.externalProductIdBySupplier": "מס מק״ט",
  "addNewCouponType.IsOneTime": "הגבלה לרכישה אחת בלבד?",
  "addNewCouponType.IsChallenge": "האם זו הטבת אתגר?",
  "addNewCouponType.StepsAmountInput": "כמות צעדים",
  "addNewCouponType.amountStepsChallenge": "כמות צעדים",
  "addNewCouponType.dailyGoalChallenge": "יעד יומי ברצף",
  "addNewCouponType.doubleDailyGoalChallenge": "יעד יומי כפול ברצף",
  "addNewCouponType.sumStepsInDaysChallenge": "סכום צעדים",
  "addNewCouponType.dailyGoalNotInARowChallenge": "יעד יומי לא ברצף",
  "addNewCouponType.percentageGoalStepsInDaysChallenge": "אחוז יעד יומי ברצף",
  "addNewCouponType.daysRangeInput": "מספר ימים לבדיקה אחורה",
  "addNewCouponType.daysSuccessionInput": "מספר ימי אתגר",
  "addNewCouponType.challengeTypeSelectLabel": "בחר סוג אתגר",
  "addNewCouponType.selectStartDay": "תאריך תחילת האתגר",
  "addNewCouponType.CodeGenerationStrategyLabel": "אסטרטגיית ייצור קוד",
  "addNewCouponType.CouponDescription": "תיאור ההטבה",
  "addNewCouponType.CouponTitle": "כותרת ההטבה",
  "addNewCouponType.paymentOptionSelect.emptyError": "בחירת מפעיל להטבת סליקה",
  "addNewCouponType.Image": "תמונה",
  "addNewCouponType.BusinessIdSelect": "בחר עסק",
  "addNewCouponType.ExternalLink": "קישור לאתר העסק",
  "addNewCouponType.ActivationTypeLabel": "בחר אופן הפעלה",
  "addNewCouponType.SupplierIdSelectLabel": "בחר ספק",
  "addNewCouponType.ActivationTypeByCode": "עם קוד",
  "addNewCouponType.ActivationTypeByLink": "עם קישור",
  "addNewCouponType.ActivationTypeBothCodeAndLink": "עם קישור וקוד",
  "addNewCouponType.ActivationTypeFitCreditCoins": "עם מטבעות בריאות",
  "addNewCouponType.ActivationTypeBothCodeAndPhone": "עם קוד ופלאפון",
  "addNewCouponType.ActivationTypeSupplierId": "מערכת הזמנות",
  "addNewCouponType.IsActive": "העלאת ההטבה כפעילה באפליקציה",
  "addNewCouponType.Instructions": "הוראות למימוש",
  "addNewCouponType.TimeTypePlaceholder": "פרק זמן",
  "addNewCouponType.TimeInputPlaceholder": "הכנס מספר",
  "addNewCouponType.TimeTypeMinutes": "דקות",
  "addNewCouponType.TimeTypeHours": "שעות",
  "addNewCouponType.TimeTypeDays": "ימים",
  "addNewCouponType.TimeTypeWeeks": "שבועות",
  "addNewCouponType.TimeTypeMonths": "חודשים",
  "addNewCouponType.TimeTypeYears": "שנים",
  "addNewCouponType.Coins": "עלות במטבעות בריאות",
  "addNewCouponType.isCreditRequired": "האם זו הטבת סליקה?",
  "addNewCouponType.RandomCodeMessage": "אין צורך להוסיף שוברים",
  "addNewCouponType.RandomCodeDropDownLabel": "קודים אקראים",
  "addNewCouponType.CodePerBusinessDropDownLabel":
    "דף שוברים קבוע שאנחנו מנפקים - קודים לפי עסק",
  "addNewCouponType.FixedCodeDropDownLabel": "קוד קבוע יחיד להטבה",
  "addNewCouponType.ExternalCouponCodeDropDownLabel":
    "רשימת קודים שונים לכל הטבה (עסק מנפיק לנו – אנחנו מנפקים לעסק)",
  "addNewCouponType.ExternalCouponInputLabel": "הנכס קודים",
  "addNewCouponType.ExternalCouponLength": "אורך של הקוד",
  "addNewCouponType.ExternalCouponAmountInput": "כמות קופונים",
  "addNewCouponType.InputFixedCouponLabel": "הכנס קוד קבוע",
  "addNewCouponType.ExternalCouponCodeInputPlaceholder": `הכנס לדוגמא:
  32131312
  12321313
  31231231`,
  "addNewCouponType.selectGroup": "בחר קבוצת איגוד הטבות",
  "addNewCouponType.groupTitle": "שם קבוצת הטבה",
  "addNewCouponType.groupTitleErrorText": "שם קבוצת הטבה",
  "addNewCouponType.groupPriceErrorText": "מחיר קבוצת הטבה",
  "addNewCouponType.chosenCouponTypeErrorText": "צירוף לקבוצת הטבה קיימת",
  "addNewCouponType.notSupprtSupplierDashboardErrorText":
    "ספק לא נתמך במערכת ספקים",
  "addNewCouponType.addToExsistGroup": "הוסף לקבוצת הטבות קיימת",
  "addNewCouponType.includeVat": "כולל מע״מ",
  "addNewCouponType.supplierSystemCoupons": "הטבה נתמכת במערכת ספקים",
  "addNewCouponType.supplierTitleErrorText":
    "בהטבת מערכת הזמנות עליך להזין ספק",
  "addNewCouponType.promotionType": "בחר סוג קידום",
  "addNewCouponType.promotionType90FirstPlace": "דקה 90 מקום 1",
  "addNewCouponType.promotionType90SecondPlace": "דקה 90 מקום 2",
  "addNewCouponType.promotionTypeHot": "חם ברמבל מקום 3+",
  "addNewCouponType.promotionTypeOnlineFirst": "אונליין מקום ראשון",
  "addNewCouponType.promotionTypeOnlineWithPatch": "אונליין מקום ראשון עם פאצ'",
  "addNewCouponType.promotionTypeCarousel": "מקום ראשון בקרסולה",
  "addNewCouponType.promotionTypeNone": "אין קידום",
  "addNewCouponType.selectOutOfStockRule": "התראת מלאי",
  "addNewCouponType.costToMushlam": "עלות בשקלים לכללית",
  "addNewCouponType.costToOvdim": "עלות בשקלים לעובדים בריא",
  "addNewCouponType.clalitSmallImage": "תמונה קטנה לכללית",
  "addNewCouponType.ActivationTypeDonation": "תרומה",
  "addNewCouponType.ActivationTypePickUp": "נקודת איסוף",
  "addNewCouponType.underTitleText": "הוספת טקסט מתחת כותרת ההטבה",
  "addNewCouponType.underPurchaseButtonText": "הוספת טקסט מתחת כפתור רכישה",
  "addNewCouponType.warningText": "הוספת טקסט אדום בפופאפ לפני השלמת רכישה",
  "addNewCouponType.choosePatch": "בחר פץ׳",
  "addNewCouponType.choosePaymentMethod": "בחר סולק",
  "addNewCouponType.choosePaymentMethod.standard": "Standard - Movement",
  "addNewCouponType.choosePaymentMethod.dorCoffeet": "Dor Coffeet",
  "addNewCouponType.choosePaymentMethod.YuviTal": "YuviTal",
  "addNewCouponType.choosePaymentMethod.YaadSarig": "Yaad Sarig",
  "addNewCouponType.isTranslate": "לתרגם הטבה",
  "addNewCouponType.translateTitle": "תרגום כותרת הטבה",
  "addNewCouponType.translateDescription": "תרגום תיאור הטבה",
  "addNewCouponType.emptyCostToSupplier": "עלות לספק",

  "editCouponType.editCouponTitle": "עריכת הטבה",
  "editCouponType.chooseCouponLabel": "בחר קופון",
  "editCouponType.editCouponButton": "ערוך הטבה",
  "editCouponType.active": "פעיל",
  "editCouponType.inactive": "לא פעיל",
  "editCouponType.selectOrderByDate": "תאריך עליית ההטבה לאפליקציה",
  "editCouponType.choosePatch.withoutPatch": "ללא פץ׳",

  "editBusiness.editBusinessFormTitle": "עריכת עסק",
  "editBusiness.subCategoriesToDelete": "בחר קטגוריות משניות למחיקה",
  "editBusiness.deleteBranch": "בחר סניף למחיקה",
  "editBusiness.submitEditCoupon": "שמירה",
  "editBusiness.editBusinessSuccess": "העריכה בוצעה בהצלחה",
  "editBusiness.editBusinessFailure": "העריכה נכשלה",
  "editBusiness.addNewBusinessBranch": "מיקום להוסיף",
  "editBusiness.businessCreatedAt": "עסק נוצר בתאריך",
  "editBusiness.currentImage": "לצפייה בתמונה הנוכחית",
  "editBusiness.businessId": "מס׳ מזהה לעסק",
  "editBusiness.addSubCategories": "בחר קטגוריות משניות להוספה",

  "editArticle.chooseArticle": "בחר מאמר",
  "editArticle.articleName": "כותרת",
  "editArticle.articleSubTitle": "כותרת משנית",
  "editArticle.smallImage": "תמונה קטנה",
  "editArticle.largeImage": "תמונה גדולה",
  "editArticle.articleHtml": "HTML למאמר",
  "editArticle.isAdmin": "הצגה לאדמינים בלבד",
  "editArticle.editArticleTitle": "עריכת מאמר",
  "editArticle.isTranslate": "לתרגם מאמר",
  "editArticle.editQuestionOrAnswer": "עריכת שאלה/תשובה",
  "editArticle.translateQuestionOrAnswer": "לתרגם שאלה/תשובה",
  "editArticle.addQuestionOrAnswer": "הוספת שאלה/תשובה",
  "editArticle.addQuestion": "הוספת שאלה",
  "editArticle.addAnswer": "הוספת תשובה",
  "editArticle.editQuestion": "עריכת שאלה",
  "editArticle.editAnswer": "עריכת תשובה",
  "editArticle.translateQuestion": "לתרגם שאלה",
  "editArticle.translateAnswer": "לתרגם תשובה",
  "editArticle.languageSelect": "בחר שפה",
  "editArticle.isCorrectAnswer": "תשובה נכונה",
  "editArticle.actionSelect": "בחר פעולה",
  "editArticle.quizTitle": "שאלות ותשובות",
  "editArticle.translatedTitle": "כותרת מתורגמת",
  "editArticle.translatedSubTitle": "כותרת משנית מתורגמת",
  "editArticle.translatedArticleHtml": "HTML למאמר מתורגם",
  "editArticle.arabic": "ערבית",
  "editArticle.english": "אנגלית",
  "editArticle.hebrew": "עברית",
  "editArticle.addLead": "להוסיף ליד?",

  "editBalance.title": "עריכת מטבעות",
  "editBalance.userIdForChange": "הזן מספר משתמש",
  "editBalance.amountCoinsForChange": "הזן מספר מטבעות ",
  "editBalance.ReasonForChange": "הזן סיבה לביצוע שינויים",
  "editBalance.textReasonForChange": "סיבת השינוי",
  "editBalance.submitChangeButton": "שמירה ועדכון",
  "editBalance.ErrorPopUpTitle": "שגיאה",
  "editBalance.editBalanceFailure": "העריכה נכשלה",
  "editBalance.SuccessPopUpTitle": "הצלחה",
  "editBalance.editBalanceSuccess": "העריכה בוצעה בהצלחה",
  "editBalance.CoinsError": "הזן מספר מטבעות",
  "editBalance.UserIdError": "הזן מספר משתמש",
  "editBalance.UserUnauthorized": "המשתמש אינו  מאושר",
  "editBalance.UserIdNotExist": "מספר משתמש אינו קיים",
  "editBalance.InvalidInputNumber": "מספר מטבעות לא תקין",
  "editBalance.InvalidReason": "הסיבה לביצוע שינויים אינה תקינה",
  "editBalance.generalServerError": "אופס משהו השתבש, נסה שוב בעוד מספר רגעים",
  "editBalance.BalanceChangeSuccess": "החשבון עודכן בהצלחה",

  "deleteUser.title": "מחיקת משתמש",
  "deleteUser.userIdForClean": "הזן מספר משתמש",
  "deleteUser.firstDeleteBtn": "מחיקה",
  "deleteUser.deleteUserBtn": "כן אני בטוח, מחק משתמש",
  "deleteUser.message": "הודעה",
  "deleteUser.areYouSureDelete":
    "האם אתה בטוח שאתה רוצה למחוק את משתמש מספר : ",
  "deleteUser.cancelDelete": "ביטול",
  "deleteUser.ErrorPopUpTitle": "שגיאה",
  "deleteUser.SuccessPopUpTitle": "הצלחה",
  "deleteUser.userNumber": "משתמש מספר -",
  "deleteUser.SuccessDelete": "נמחק בהצלחה",
  "deleteUser.UserIdError": "הזן מספר משתמש",
  "deleteUser.UserUnauthorized": "אינך רשאי למחוק משתמשים",
  "deleteUser.UserIdNotExist": "משתמש לא קיים במערכת",
  "deleteUser.generalServerError": "אופס משהו השתבש, נסה שוב בעוד מספר רגעים",

  "updateUserSegment.title": "עריכת סגמנט משתמש",
  "updateUserSegment.userIdForChange": "הזן מספר משתמש",
  "updateUserSegment.selectSegmentLabel": "בחר סגמנט",
  "updateUserSegment.textReasonForChange": "סיבת השינוי",
  "updateUserSegment.UserIdEmptyError": "הזן מספר משתמש",
  "updateUserSegment.selectSegmentEmptyError": "בחר סגמנט",
  "updateUserSegment.ReasonForChangeEmptyError": "הזן סיבה לביצוע שינויים",
  "updateUserSegment.submitChangeButton": "שמירה ועדכון",
  "updateUserSegment.ErrorPopUpTitle": "שגיאה",
  "updateUserSegment.segmentUpdateFailure": "העריכה נכשלה",
  "updateUserSegment.SuccessPopUpTitle": "הצלחה",
  "updateUserSegment.updateUserSegmentSuccess": "העריכה בוצעה בהצלחה",
  "updateUserSegment.UserUnauthorized": "המשתמש אינו  מאושר",
  "updateUserSegment.UserIdNotExist": "מספר משתמש אינו קיים",
  "updateUserSegment.InvalidInputNumber": "סגמנט לא תקין",
  "updateUserSegment.InvalidReason": "הסיבה לביצוע שינויים אינה תקינה",
  "updateUserSegment.generalServerError":
    "אופס משהו השתבש, נסה שוב בעוד מספר רגעים",
  "updateUserSegment.segmentUpdateSuccess": "החשבון עודכן בהצלחה",

  "businessReport.title": "הנפקת דוחות",
  "businessReport.businessReport": "דוח עסק",
  "businessReport.busisnessInput": "עסק",
  "businessReport.selectStartDay": "תאריך תחילת הדוח",
  "businessReport.selectExpireDay": "תאריך סיום הדוח",
  "businessReport.contactEmail": "כתובת מייל לשליחת הדוח",
  "businessReport.busisnessInputEmptyError": "בחר עסק",
  "businessReport.selectStartDayEmptyError": "בחר תאריך הנפקה",
  "businessReport.selectExpireDayEmptyError": "בחר תאריך תפוגה",
  "businessReport.contactEmailEmptyError": "נא הזן כתובת מייל ",
  "businessReport.download": "הורדה",
  "businessReport.sendEmail": "שלח לי למייל",
  "businessReport.emailSuccess": "הדוח נשלח למייל שלך בהצלחה!",
  "businessReport.ErrorPopUpTitle": "שגיאה",
  "businessReport.segmentUpdateFailure": "העריכה נכשלה",
  "businessReport.SuccessPopUpTitle": "הצלחה",
  "businessReport.businessReportSuccess": "העריכה בוצעה בהצלחה",
  "businessReport.UserUnauthorized": "המשתמש אינו  מאושר",
  "businessReport.InvalidInputNumber": "לא תקין",
  "businessReport.generalServerError":
    "אופס משהו השתבש, נסה שוב בעוד מספר רגעים",
  "businessReport.segmentUpdateSuccess": "הדוח ירד בהצלחה",
  "businessReport.businessNotFound": "העסק לא נמצא במערכת",

  "reportSelect.title": "הנפקת דוחות",
  "reportSelect.select": "בחר דוח",
  "reportSelect.noMatch": "אין התאמה לדוח",
  "reportSelect.userNotAllowedToDownloadB2cReports":
    "אינך רשאי להוריד דוחות B2C",

  "auctionReport.auctionsReport": "דוח מכירה פומבית",
  "auctionReport.selectAuctionEmptyError": "לא נבחרה מכירה פומבית",
  "auctionReport.auctionSelectPlaceHolder": "מכירה פומבית",
  "auctionReport.auctionNotFound": "המכירה לא נמצאה במערכת",
  "auctionReport.auctionNotFound2": "לא נמצאה מכירה פומבית בעסק זה",

  "articleReport.articleReport": "דוח מאמרים",

  "promotionReport.promotionReport": "דוח קידום",
  "promotionReport.selectPromotionEmptyError": "לא נבחר קידום",
  "promotionReport.promotionSelectPlaceHolder": "קידום",
  "promotionReport.promotionNotFound": "לא נמצא קידום בעסק זה",

  "addNewAuction.addNewAuctionFormTitle": "העלת מכירה פומבית",
  "addNewAuction.addNewAuctionTitle": "שם מכירה פומבית",
  "addNewAuction.addNewAuctionStartDate": "תאריך תחילת המכירה הפומבית",
  "addNewAuction.addNewAuctionEndDate": "תאריך סיום המכירה הפומבית",
  "addNewAuction.addNewAuctionCostToBusiness": "עלות לעסק",
  "addNewAuction.addNewAuctionExternalLinkUrl": "קישור חיצוני",
  "addNewAuction.addNewAuctionExternalButtonText": "טקסט של כפתור קישור חיצוני",
  "addNewAuction.addNewAuctionExternalButtonDefaultText": "תראו לי",
  "addNewAuction.addNewAuctionBccEmail": "כתובת מייל",
  "addNewAuction.addNewAuctionDescription": "תיאור מכירה פומבית",
  "addNewAuction.addNewAuctionIsSprintCheckbox": "?האם זו ספרינט",
  "addNewAuction.addNewAuctionStartSprintDate": "תאריך תחילת הספרינט",
  "addNewAuction.addNewAuctionSubmitButton": "צור מכירה פומבית",
  "AddNewAuction.AddNewAuctionImage": "תמונתה",

  "editAuction.editAuctionFormTitle": "עריכת מכירה פומבית",
  "editAuction.editAuctionAuctionSelect": "בחר מכירה פומבית",

  "business.sideBar.pricing.title": "עוד צעד קדימה",
  "business.sideBar.switchBusiness.title": "מעבר בין פאנלי עסקים",
  "business.home.title": "פאנל עסקים",
  "business.home.impressionGraph.title": 'סה"כ חשיפות',
  "business.home.impressionGraph.linesData": "חשיפות",
  "business.home.ClicksGraph.title": 'סה"כ קליקים',
  "business.home.ClicksGraph.linesData": "קליקים",
  "business.home.impressionCube.title": 'סה"כ צפיות ב- 30 הימים האחרונים',
  "business.home.clicksCube.title": 'סה"כ קליקים ב- 30 הימים האחרונים',
  "business.home.clicksCube.count": "{{count}} קליקים",
  "business.home.clicksCube.purchases.per.coupon": "{{count}} רכישות",
  "business.home.purchasesCube.title": 'סה"כ הטבות שנרכשו ב- 30 הימים האחרונים',
  "businessHome.ImpressionChart.dropDownOption.6Months": "בחצי שנה האחרונה",
  "businessHome.ImpressionChart.dropDownOption.30days": "ב-30 יום האחרונים",
  "businessHome.ImpressionChart.dropDownOption.3Months": "ב-3 חודשים האחרונים",
  "businessHome.ClicksPieChart.clickByCoupons": "התפלגות קליקים לפי הטבה",
  "businessHome.ClicksPieChart.clickByCoupons.toolTip":
    "בגרף זה מוצגים לך התפלגות הקליקים על ההטבות שברשותך",
  "business.home.PositionCube.title": "מיקומך בקטגורית {{categoryName}}",
  "business.home.leadingCoupons.title": "הטבות בית העסק",
  "business.home.leadingCoupons.subheader": "ביצועים ב-30 יום האחרונים",
  "business.home.leadingCoupons.subTitle":
    "הטבה לא פעילה באפור עם טקסט צף- פעיל/לא פעיל",

  "business.home.impressionCube.tooltip":
    "מספר הפעמים שבית העסק שלך נחשף לחברים וחברות באפליקציה ב- 30 ימים האחרונים",
  "business.home.clicksCube.tooltip":
    "מספר הפעמים בו חברי האפליקציה הקליקו על העסק שלך או על הטבות שברשותך ב- 30 הימים האחרונים",
  "business.home.purchasesCube.tooltip":
    "מספר ההטבות שרכשו חברי האפליקציה מבית העסק שלך ב- 30 ימים האחרונים",
  "business.home.categoryPositionCube.tooltip": `מיקום העסק שלך ביחס לעסקים הנמצאים .באותה קטגוריה . 
  מה משפיע על המיקום? 
  פעילות החברים והחברות באפליקציה, 
   איכות ההטבות ומספר הקידומים של העסק באפליקציה.`,

  "business.home.cubes.clicksOnSite": `מספר הכניסות לאתר ב- 30 הימים האחרונים`,
  "business.home.cubes.phone": `מספר העברות לטלפון ב- 30 הימים האחרונים`,
  "business.home.cubes.instagram": `מספר הכניסות לאינסטגרם ב- 30 הימים האחרונים`,
  "business.home.cubes.facebook": `מספר הכניסות לדף הפייסבוק ב- 30 הימים האחרונים`,
  "businees.home.cubes.categoryPosition":
    " {{categoryPositionFormatted}}  מתוך {{businessesInTheCategoryFormatted}} ",

  "business.pricing.title": `לוקחים את העסק עוד צעד קדימה!`,

  "business.pricing.subTitle": `רוצה להופיע במקום הכי נצפה באפליקציה? לשלוח הודעה ישירה לטלפון לקהל שרלוונטי רק אליך?
   או לגרום לאלפי כניסות לאתר?
   הכנו לכם חבילות קידום מוזלות שיקפיצו את הביצועים שלכם באפליקציה!
   בוחרים חבילה ומתחילים לקדם.. שנתחיל?`,

  "business.pricing.popUp.title": "הבקשה נשלחה בהצלחה",
  "business.pricing.popUp.content": ` אנחנו שמחים שבחרת לקחת את העסק שלך צעד אחד קדימה!
   רק נשאר לנו לסגור פרטים אחרונים לפני שמתחילים..
   אז ב- 48 השעות הקרובות (עד 2 ימי עסקים) כשיצלצל לך הטלפון-יש מצב שזה אנחנו :)`,
  "business.pricing.popUp.closeButton": "סגור",
  "business.pricing.mostViewed": "הופעה בקטגוריה הכי נצפית",
  "businees.pricing.comboSale": "מבצע קומבו",
  "businees.pricing.goldPackage": "חבילת זהב",
  "businees.pricing.silverPackage": "חבילת כסף",
  "businees.pricing.bronzePackage": "חבילת ארד",
  "businees.pricing.wantGold": "אני רוצה חבילת זהב!",
  "businees.pricing.wantSilver": "אני רוצה חבילת כסף!",
  "businees.pricing.wantBronze": "אני רוצה חבילת ארד!",
  "businees.pricing.wantPackage": "אשמח לפרטים נוספים",
  "business.pricing.90minutes": "הטבה של הדקה ה- 90",
  "business.pricing.pm": "הודעות אישיות (push notification)",
  "business.pricing.expose48hours": "חשיפה במשך 48 שעות",
  "business.pricing.expose4days": "חשיפה במשך 4 ימים",
  "business.pricing.expose3daysCategory":
    "חשיפה במשך 3 ימים בקטגוריה הכי נצפית",
  "business.pricing.hotInRumble": "הופעה בקטגורית חם ב- Rumble",
  "business.pricing.auction": "מכירה פומבית",
  "business.pricing.1500redirect": "1500 העברות לאתר בממוצע",
  "business.pricing.firstPlace": "הופעה במקום 1 בקטגוריה הכי נצפית",
  "business.pricing.branding": "מיתוג ההטבה",
  "business.pricing.mostImpression": "בקטגוריה הכי נצפית ",
  "business.pricing.position": "הופעה במקום ",
  "business.pricing.goldPack.oldPrice": "₪1742",
  "business.pricing.goldPack.newPrice": "₪1219",
  "business.pricing.silverPack.oldPrice": "₪1248",
  "business.pricing.silverPack.newPrice": "₪1109",
  "business.pricing.bronzePack.oldPrice": "₪799",
  "business.pricing.bronzePack.newPrice": "₪759",

  "switchBusiness.BusinessIdSelect": "בחר עסק",
  "switchBusiness.switchToChosenBusiness": "עבור לעסק הנבחר",
  "switchBusiness.emptyBusinessIdError": "לא נבחר עסק",
  "switchBusiness.failedToChangeBusiness": "נסיון מעבר לעסק נכשל",
  "switchBusiness.ErrorPopUpTitle": "שגיאה",

  "screenHome.meausermentsTotal.title": "התפלגות משתמשים עבור דיווח מדדים",
  "screenHome.meausermentsTotal.articles": "קראו מאמרים",
  "screenHome.meausermentsTotal.water": "דיווחו על שתייה",
  "screenHome.meausermentsTotal.sleep": "הזינו שעות שינה",
  "screenHome.meausermentsTotal.bmi": "מדדו BMI",
  "screenHome.meausermentsTotal.nutrition": "הזינו תזונה",

  "login.title": "התחברות",
  "login.enterDetails": "הזן אימייל וסיסמה כדי להתחבר",
  "login.email": "אימייל",
  "login.password": "סיסמה",
  "login.button": "התחבר",
  "login.segmentCard.chooseSegment.label": "יש לבחור ארגון",
  "login.segmentCard.chooseSegment.button": "כניסה",
  "login.segmentCard.chooseSegment.dropDownLabel": "רשימת ארגונים",
  "login.mailSuccessResetPassword.title": "איפוס סיסמה",
  "login.mailSuccessResetPassword.text":
    "שלחנו לך לינק איפוס סיסמה לתיבת המייל",
  "login.mailSuccessResetPassword.button": "אחלה, תודה",
  "screenLogin.errorText.resetPassword": "על מנת להתחבר עליך לאפס את הסיסמה",
  "screenLogin.errorText.maintenence": "אופס אנחנו למטה, חוזרים עוד מעט...",

  "support.common.withPhone.subtitle": "לקבלת פרטים יש להזין ת.ז או טלפון",
  "support.common.subtitle": "לקבלת פרטים יש להזין ת.ז",
  "support.common.withPhone.placeHolder": "מספר ת.ז או טלפון",
  "support.common.placeHolder": "מספר ת.ז",

  "suppliers.validation.validationIsNotExist": "ולידציה לא קיימת",
  "suppliers.validation.currentValidation": "ולידציה נוכחית:",
  "suppliers.validation.changeValidation": "שינוי ולידציה",
  "suppliers.validation.changeValidationIsSucceeded":
    "שינוי ולידציה בוצע בהצלחה",

  "suppliers.projectManagerHome.suppliers": "ספקים",
  "suppliers.projectManagerHome.monthlyReportPopUp": "דו״ח ספק חודשי",
  "suppliers.projectManagerHome.monthlyReportIsSucceededPopUp":
    "דו״ח חודשי נשלח בהצלחה לכתובת {{email}}",
  "suppliers.projectManagerHome.monthlyReportIsOKPopUp": "אחלה",
  "suppliers.projectManagerHome.selectMonthAndYear": "בחר חודש ושנה",
  "suppliers.supplierHome.noErrorCodeWereEntered": "יש להזין קודי הטבה.",
  "suppliers.supplierHome.codesAreNotValid":
    "קודי הטבה לא תקינים, נא צור קשר עם מנהל המערכת",
  "suppliers.supplierHome.selectMonthAndYear": "בחר חודש ושנה",
  "suppliers.supplierHome.completeEnterCodesPopUp": "סיום הזנת קודי הטבה",
  "suppliers.supplierHome.areYouSureYouCompliteEnterCodesPopUp":
    "האם אתה בטוח שסיימת להזין קודים על כל ההטבות שלך לחודש זה?",
  "suppliers.supplierHome.areYouSureYouCompliteEnterCodesPopUpYes": "כן",
  "suppliers.supplierHome.areYouSureYouCompliteEnterCodesPopUpNo": "לא",
  "suppliers.supplierHome.completeEnterCodesIsSuccessedPopUp":
    "הזנת קודי הטבה הסתיימה בהצלחה, נתראה בחודש הבא!",
  "suppliers.supplierHome.completeEnterCodesOkPopUp": "אחלה",
  "suppliers.supplierHome.verifyCodesPopUp": "אימות קודי הטבה",
  "suppliers.supplierHome.verifyCodesOkPopUp": "אחלה",
  "suppliers.supplierHome.successLoadCodes": `בהטענה זאת הטענת {{totalCodesLoaded}} קודים מתוכם {{validCodesAmount}} תקינים ו{{exceptionalCodesAmount}} חריגים.`,
  "suppliers.supplierHome.otpCode": "קוד אימות",
  "suppliers.supplierHome.previousMonthTitle": "הזנת קודים על חודש קודם",
  "suppliers.supplierHome.insertCodesOnPreviousMonthQuestion":
    "האם אתה בטוח שברצונך להזין על חודש קודם?",
  "suppliers.supplierHome.insertCodesOnPreviousMonthAnswerYes": "כן",
  "suppliers.supplierHome.insertCodesOnPreviousMonthAnswerNo": "לא",
  "suppliers.supplierHome.insertOtpCodeForInsertCodes":
    "על מנת להזין על חודש זה עליך להכניס קוד אימות",
  "suppliers.supplierHome.otpCodeIsInvalid": "קוד לא חוקי",
  "suppliers.supplierHome.otpCodeVerifyLimiterExceeded":
    "בוצעו מספר רב מדי של ניסיונות. יש לנסות שוב מאוחר יותר.",
  "suppliers.supplierHome.sendOtpCodeLimiterExceeded":
    "בוצעו מספר רב מדי של ניסיונות. יש לנסות שוב מאוחר יותר.",
  "suppliers.supplierHome.mailSendFailed": "הפעולה נכשלה, מייל לא נשלח.",
  "suppliers.supplierHome.verifyOtpCode": "אימות קוד",
  "suppliers.supplierHome.askForOtpCode": "בקש קוד אימות",
  "suppliers.supplierHome.otpCodesIsSuccessedPopUp": "קוד אימות נשלח בהצלחה",
  "suppliers.supplierHome.otpCodesContactManagerProjectPopUp":
    "ע״מ לקבל את קוד האימות, אנא צור קשר עם קובי.",
  "suppliers.supplierHome.otpCodesOkPopUp": "אחלה",
  "suppliers.supplierHome.insertRefund": "הזן זיכוי כספי",
  "suppliers.supplierHome.sendCodesButton": "שלח קודים לבדיקה!",
  "suppliers.supplierHome.insertCodesPlaceHolder": "הזן קודי הטבה",
  "suppliers.supplierHome.selectCouponType": "בחר הטבה",
  "suppliers.supplierHome.toolTipRefund": `ספק יקר,
   באם השתנה באמצע החודש מחיר ההטבה או שהחודש עליך להוציא זיכוי ללקוח
   הזן את סכום הזיכוי הניתן.`,
  "suppliers.home.finishLoadCodesButton": "סיימתי להזין קודים לחודש זה!",
  "suppliers.home.finishLoadCodesTitle": "סיום הזנת קודים",
  "suppliers.home.finishLoadCodesToolTip": `ספק יקר, בלחיצה על כפתור זה הינך מצהיר כי סיימת להזין קודים לכל ההטבות שברשותך לחודש זה וכי אין צורך לתזכר אותך לעשות כן`,
  "suppliers.projectManager.validationsList": "ולידציות",
  "suppliers.home.suuplierDashboardTitle": "פאנל ספקים",
  "suppliers.supplierHome.exceptionalsReprt": `נשלח אליך דו״ח חריגים למייל.`,
  "suppliers.supplierHome.otpPolicy": `הינך מועבר למסך הזנת קודים על חודש קודם.
   יש לציין כי יהיה עלייך להשלים את פעולת ההזנה באופן מיידי. 
   במידה ולא תעשה כן, יהיה עלייך לבקש קוד אימות מחדש.`,
  "suppliers.projectManagerHome.getMonthlyReportButton": "קבל דו״ח ספק חודשי",
  "suppliers.projectManagerHome.generalErrorSendReport":
    "אירעה שגיאה בעת שליחת הדו״ח, אנא פנה אלינו לבירור",
  "suppliers.sideBar.validations": "ולידציות",
  "suppliers.supplierHome.otpPolicyTitle": "קוד אימות אושר בהצלחה",
  "suppliers.supplierHome.otpPolicyOkButton": "קיבלתי",
  "suppliers.projectManager.validations.removeDolarsFromBegining":
    "הסר $$ מההתחלה מרשימת הקודים ביוביטל",
  "suppliers.projectManager.validations.removeCharactersAfterHyphen":
    "הסר תווים אחרי הופעת המקף(כולל המקף) מרשימת הקודים ביוביטל",
  "suppliers.projectManager.validations.removeCharactersBeforeHyphen":
    "הסר תווים לפני הופעת המקף(כולל המקף) מרשימת הקודים של הספק",
  "suppliers.supplierHome.loadOnPreviousMonthPopUpTitle":
    "הזנת קודים על חודש קודם",
  "suppliers.ProjectManagerHome.MonthlyReportTitle": "הוצאת דו״ח ספק חודשי",
  "suppliers.ProjectManagerHome.MonthlyCouponsSummaryReportTitle":
    "הוצאת דו״ח הטבות מסכם",
  "suppliers.home.projectManagerCouponsReportSummaryToolTip":
    "הוצאת דו״ח סיכום לכלל ההטבות של כל הספקים לחודש נבחר",
  "suppliers.projectManagerHome.getMonthlyCouponsSummaryReportButton":
    "קבל דו״ח הטבות מסכם",
  "suppliers.projectManagerHome.monthlySummaryCouponsReportPopUp":
    "דו״ח הטבות מסכם",
  "suppliers.projectManagerSupplier.title": "מימוש הטבות",

  "suppliers.projectManagerHome.title": "הנפקת דוחות מימושים ורכישות",
  "suppliers.supplierAndCouponReports.title": "דוחות ספקים והטבות",
  "suppliers.billingReportSystem.numberOfCodes": `הזנת {{numberOfCodes}} קודים`,
  "suppliers.billingReportSystem.projectManagerSupplier.title": `מימוש קודים`,
  "suppliers.billingReportSystem.getAllPurchasesButton.text": `קבל דו״חות רכישות`,
  "suppliers.billingReportSystem.rejectCodes.text": `להסיר קודים`,
  "suppliers.billingReportSystem.isFinal.text": `דוח סופי`,
  "suppliers.billingReportSystem.rejectCodes.isDelivery": `האם הקודים ממערכת הזמנות?`,
  "suppliers.billingReportSystem.rejectCodes.successPopUpMessage": ` הוסרו בהצלחה {{numberOfCodesRejected}} קודים`,
  "suppliers.billingReportSystem.isPerSupplier": `להוריד דו״ח פר ספק?`,
  "suppliers.billingReportSystem.selectSupplier": `בחר ספק/ים`,

  "suppliers.infoCubes.unvalidatedPurchases": `רכישות`,
  "suppliers.infoCubes.validatedPurchases": ` מימושים`,
  "suppliers.infoCubes.totalUsersToDemandPayment": `תקציב פתוח`,
  "suppliers.infoCubes.monthNumber": `חודש {{monthNumber}}`,
  "suppliers.infoCubes.allTime": `מאז ומעולם`,
  "suppliers.infoCubes.percentageToolTip": `אחוז תקציב מומש על ידי ספקים`,
  "suppliers.infoCubes.unvalidatedPurchasesToolTip": `מה שווי השוברים שנרכשו בתקופה`,
  "suppliers.infoCubes.validatedPurchasesToolTip": `כמה שווי ההטבות שנשרפו במערכת ספקים בתקופה`,
  "suppliers.infoCubes.totalUsersToDemandPaymentToolTip": `כמות משתמשים שעמדו ביעד כפול 8.33`,

  "suppliers.sideBar.support:title": "לתמיכה ושירות- לחצו כאן",
  "suppliers.sideBar.support:subject": "תמיכה ושירות מערכת ספקים",
  "suppliers.sideBar.support:description": `
שם הספק: {{userName}}\n
שם פרטי:\n
תפקיד:\n
איך אנחנו יכולים לעזור:\n
מייל:\n
טלפון:`,

  "general.yes": "כן",
  "general.no": "לא",
  "general.great": "מצוין",
  "general.addText": "הזן טקסט",
  "general.text": "טקסט:",

  "screenSendNotificationToUser.title": "שליחת נוטיפיקציה",
  "police.segment.name": "משטרת ישראל",

  "excel.sheet.summaries": "סיכומים",
  "excel.sheet.openingRate": "קצב פתיחת האפליקציה",
  "excel.sheet.bonuses": "התפלגות בונוסים",
  "excel.sheet.joiningMembersRate": "קצב הצטרפות חברים חדשים",
  "excel.sheet.referral": "חבר מביא חבר",
  "excel.sheet.averageImprovement": `ממוצע שיפור {{selectedSegmentName}} החודש`,
  "excel.sheet.membersNumber": `מספר חברי {{selectedSegmentName}} כעת`,
  "excel.sheet.membersNumber.lastWeek": `מספר חברי {{selectedSegmentName}} שהצטרפו בשבוע האחרון`,
  "excel.sheet.coinsTotal": `כל מטבעות חברי {{selectedSegmentName}}`,
  "excel.sheet.weeklyImprovement": `שיפור שבועי {{selectedSegmentName}}`,
  "excel.sheet.type": "סוג",
  "excel.sheet.amount": "כמות",
  "excel.sheet.date": "תאריך",
  "excel.sheet.user": "משתמש",
  "excel.sheet.addedFriends": "חברים שצורפו",
  "excel.sheet.activeUsers": "קצב גדילת משתמשים פעילים",
};
