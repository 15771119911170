import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import useRouter from "utils/useRouter";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenLoginStore, ScreenLoginStoreProvider } from "./store";
import { Card, CardContent } from "@mui/material";
import { LoginCard } from "./components/loginCard";
import { ChooseSegmentCard } from "./components/chooseSegmentCard";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6, 2),
    backgroundColor: theme.palette.white,
  },
  card: {
    width: 400,
    maxWidth: "100%",
    overflow: "unset",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
}));

export const Login = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenLoginStore(rootStore));
  const classes = useStyles();
  const router = useRouter();

  const onClick = () => {
    store.onSubmitDashboardSegment(router);
  };

  const onSegmentChanged = (segment) => {
    store.setSelectedSegment(segment);
  };

  const cardContent = store.allowedSegments ? (
    <ChooseSegmentCard
      onClick={onClick}
      allowedSegments={store.allowedSegments}
      selectedSegment={store.selectedSegment}
      errorText={store.errorText}
      onSegmentChanged={onSegmentChanged}
    />
  ) : (
    <LoginCard errorText={store.errorText} />
  );
  return (
    <ScreenLoginStoreProvider value={store}>
      <Page className={classes.root} title="Login">
        <Card className={classes.card}>
          <CardContent className={classes.content}>{cardContent}</CardContent>
        </Card>
      </Page>
    </ScreenLoginStoreProvider>
  );
});
