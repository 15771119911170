import React from "react";
import { makeObservable, action, observable } from "mobx";
import { login as loginServer } from "./server/login";
import { t } from "common/localization/translate";
import { getSegmentPathById } from "commonStores/analytics/userInfoStore/logic/getSegmentPathById";
import { getSegmentIdByPath } from "commonStores/analytics/userInfoStore/logic/getSegmentIdByPath";
import { getErrorTextByErrorCode } from "./logic/getErrorTextByErrorCode";
import { resetPasswordSendEmail } from "./server/resetPassword";
import { dashboardTypes } from "screens/loginV2/store/logic/dashboardTypes";

const ScreenLoginStoreContext = React.createContext({});
export const ScreenLoginStoreProvider = ScreenLoginStoreContext.Provider;
export const useScreenLoginStore = () =>
  React.useContext(ScreenLoginStoreContext);

class ScreenLoginStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
    makeObservable(this, {
      email: observable,
      appType: observable,
      isResetPasswordNeeded: observable,
      password: observable,
      errorText: observable,
      allowedSegments: observable,
      selectedSegment: observable,
      isShowSuccessResetPasswordPopUp: observable,
      setEmail: action.bound,
      setPassword: action.bound,
      login: action.bound,
      resetPassword: action.bound,
      initProperties: action.bound,
      setAllowedSegments: action.bound,
      setSelectedSegment: action.bound,
      onSubmitDashboardSegment: action.bound,
      setErrorText: action.bound,
    });
  }

  initProperties() {
    this.email = "";
    this.appType = null;
    this.isResetPasswordNeeded = false;
    this.password = "";
    this.errorText = null;
    this.allowedSegments = null;
    this.selectedSegment = null;
    this.isShowSuccessResetPasswordPopUp = false;
  }

  login = async (history, pathname) => {
    const segmentIdByPath = getSegmentIdByPath(pathname);
    const loginInfo = {
      email: this.email,
      password: this.password,
      segmentIdByPath,
      dashboardType: dashboardTypes.ORGANIZATION,
    };
    if (!this.email || !this.password) {
      this.setErrorText(t("screenLogin.errorText"));
      return;
    }
    const { isSuccess, extra, errorCode } = await loginServer(loginInfo);
    if (isSuccess) {
      const { allowedSegments, currentSegment } = extra;
      if (allowedSegments.length > 1 && !currentSegment) {
        this.setErrorText(null);
        this.setAllowedSegments(allowedSegments);
        return;
      }
      const segmentToShow = currentSegment
        ? currentSegment
        : allowedSegments[0];
      this.rootStore.userInfoStore.setCurrentSegment(segmentToShow);
      this.rootStore.userInfoStore.setAllowedSegments(allowedSegments);
      const segmentPath = getSegmentPathById(segmentToShow.segmentId);
      history.push(`/organization/home/${segmentPath}`);
      return;
    }
    if (extra && extra.isResetPasswordNeeded) {
      this.isResetPasswordNeeded = extra.isResetPasswordNeeded;
      this.appType = extra.appType;
      this.email = extra.email;
    }
    const errorText = getErrorTextByErrorCode(errorCode);
    this.setErrorText(errorText);
  };

  resetPassword = async ({ isFromOrganization }) => {
    if (!this.email) {
      this.setErrorText(t("screenLogin.editPassword.errorText"));
    } else {
      this.setErrorText(null);
      this.isShowSuccessResetPasswordPopUp = true;
    }
    await resetPasswordSendEmail({ email: this.email, isFromOrganization });
  };

  onSubmitDashboardSegment = (router) => {
    if (!this.selectedSegment) {
      this.setErrorText(t("screenLogin.chooseSegment.errorText"));
      return;
    }
    const segmentPath = getSegmentPathById(this.selectedSegment.segmentId);
    this.rootStore.userInfoStore.setCurrentSegment(this.selectedSegment);
    this.rootStore.userInfoStore.setAllowedSegments(this.allowedSegments);

    router.history.push(`/organization/home/${segmentPath}`);
  };

  setEmail(email) {
    this.email = email;
  }

  setPassword(password) {
    this.password = password;
  }

  setSelectedSegment(selectedSegment) {
    this.selectedSegment = selectedSegment;
  }

  setAllowedSegments(allowedSegments) {
    this.allowedSegments = allowedSegments;
  }

  setErrorText(text) {
    this.errorText = text;
  }
}

export function createScreenLoginStore(rootStore) {
  const store = new ScreenLoginStore(rootStore);
  return store;
}
