import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@mui/styles";
import Slide from '@mui/material/Slide';
import { observer } from 'mobx-react-lite';
import { useScreenLoginStore } from "../store";
import { t } from "common/localization/translate";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: 'Assistant',
            fontWeight: 'bold'
        }
    },
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        color: theme.palette.white,
        fontFamily: 'Assistant',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        }
    },
    content: {
        marginTop: 10,
        fontFamily: 'Assistant',
        fontSize: 14
    }
}));

export const EmailSentSuccessfullyPopUp = observer(() => {
    const store = useScreenLoginStore();
    const classes = useStyles();

    const handleClose = () => {
        store.isShowSuccessResetPasswordPopUp = false;
    };

    return (
        <Dialog open={store.isShowSuccessResetPasswordPopUp} TransitionComponent={Transition} keepMounted onClose={handleClose}>
            <DialogTitle className={classes.title}>{t("login.mailSuccessResetPassword.title")}</DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.content}>{t("login.mailSuccessResetPassword.text")}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.buttons} onClick={handleClose}>{t("login.mailSuccessResetPassword.button")}</Button>
            </DialogActions>
        </Dialog>
    );
})